import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4be3ea8c = () => interopDefault(import('../pages-medi/ac.vue' /* webpackChunkName: "" */))
const _318219d8 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _7e744ad2 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/index.vue' /* webpackChunkName: "pages/checkout/index/index" */))
const _2d12eb1a = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/auth.vue' /* webpackChunkName: "pages/checkout/index/auth" */))
const _3e8a5aab = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step2.vue' /* webpackChunkName: "pages/checkout/index/step2" */))
const _3e98722c = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step3.vue' /* webpackChunkName: "pages/checkout/index/step3" */))
const _3855c9d8 = () => interopDefault(import('../pages/customer.vue' /* webpackChunkName: "pages/customer" */))
const _5f298e5b = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _cf77ef20 = () => interopDefault(import('../pages/customer/agreements.vue' /* webpackChunkName: "pages/customer/agreements" */))
const _0692a64c = () => interopDefault(import('../pages/customer/beneficiaries.vue' /* webpackChunkName: "pages/customer/beneficiaries" */))
const _086210d2 = () => interopDefault(import('../pages/customer/card.vue' /* webpackChunkName: "pages/customer/card" */))
const _5bc9dd91 = () => interopDefault(import('../pages/customer/edit.vue' /* webpackChunkName: "pages/customer/edit" */))
const _8d6bbaa2 = () => interopDefault(import('../pages/customer/orders/index.vue' /* webpackChunkName: "pages/customer/orders/index" */))
const _00f4e0fd = () => interopDefault(import('../pages/customer/questionnaire/mental-health.vue' /* webpackChunkName: "pages/customer/questionnaire/mental-health" */))
const _2a684dd3 = () => interopDefault(import('../pages/customer/questionnaire/participants.vue' /* webpackChunkName: "pages/customer/questionnaire/participants" */))
const _dde1f652 = () => interopDefault(import('../pages/customer/orders/_id.vue' /* webpackChunkName: "pages/customer/orders/_id" */))
const _d765e750 = () => interopDefault(import('../pages-medi/index.vue' /* webpackChunkName: "" */))
const _f1b46d46 = () => interopDefault(import('../pages/invoice-data-form.vue' /* webpackChunkName: "pages/invoice-data-form" */))
const _d3a8bae0 = () => interopDefault(import('../pages/mediclub/index.vue' /* webpackChunkName: "pages/mediclub/index" */))
const _234b3f2c = () => interopDefault(import('../pages/modal-test.vue' /* webpackChunkName: "pages/modal-test" */))
const _00e9d2de = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _2634724c = () => interopDefault(import('../pages/rate.vue' /* webpackChunkName: "pages/rate" */))
const _ea4910be = () => interopDefault(import('../pages/complaint.vue' /* webpackChunkName: "pages/complaint" */))
const _15b0233b = () => interopDefault(import('../pages-medi/sitemap.vue' /* webpackChunkName: "" */))
const _744564c4 = () => interopDefault(import('../pages/terms-form.vue' /* webpackChunkName: "pages/terms-form" */))
const _399f3b7c = () => interopDefault(import('../pages-medi/thank-you.vue' /* webpackChunkName: "" */))
const _7b98dfc4 = () => interopDefault(import('../pages/update-beneficiary/index.vue' /* webpackChunkName: "pages/update-beneficiary/index" */))
const _55e5b7b9 = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _49ac1bbc = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _38e86447 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _4853ed96 = () => interopDefault(import('../pages/auth/password-change.vue' /* webpackChunkName: "pages/auth/password-change" */))
const _41b063da = () => interopDefault(import('../pages/auth/password-reset.vue' /* webpackChunkName: "pages/auth/password-reset" */))
const _11c093a0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _4d2063ad = () => interopDefault(import('../pages/c/null.vue' /* webpackChunkName: "pages/c/null" */))
const _1d96f4a2 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/EmptyCart.vue' /* webpackChunkName: "pages/checkout/EmptyCart" */))
const _9649bb80 = () => interopDefault(import('../pages/p/null.vue' /* webpackChunkName: "pages/p/null" */))
const _aa27f298 = () => interopDefault(import('../pages-medi/search/q.vue' /* webpackChunkName: "" */))
const _21f8d9f0 = () => interopDefault(import('../pages/update-beneficiary/thank-you.vue' /* webpackChunkName: "pages/update-beneficiary/thank-you" */))
const _4225d103 = () => interopDefault(import('../pages-medi/ac/_url.vue' /* webpackChunkName: "" */))
const _022631a8 = () => interopDefault(import('../pages/mediclub/_page.vue' /* webpackChunkName: "pages/mediclub/_page" */))
const _79226fa1 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _e6bf151e = () => interopDefault(import('~/pages/p/_slug.vue' /* webpackChunkName: "" */))
const _fdb65c10 = () => interopDefault(import('../pages-medi/category/_.vue' /* webpackChunkName: "" */))
const _4b219c92 = () => interopDefault(import('../pages-medi/a/_.vue' /* webpackChunkName: "" */))
const _de3e0eb2 = () => interopDefault(import('../pages/a/_.vue' /* webpackChunkName: "pages/a/_" */))
const _0aa521ae = () => interopDefault(import('../pages/c/_.vue' /* webpackChunkName: "pages/c/_" */))
const _b9538156 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ac",
    component: _4be3ea8c,
    name: "ac___pl"
  }, {
    path: "/checkout",
    component: _318219d8,
    children: [{
      path: "",
      component: _7e744ad2,
      name: "checkout-index___pl"
    }, {
      path: "auth",
      component: _2d12eb1a,
      name: "checkout-index-auth___pl"
    }, {
      path: "step2",
      component: _3e8a5aab,
      name: "checkout-index-step2___pl"
    }, {
      path: "step3",
      component: _3e98722c,
      name: "checkout-index-step3___pl"
    }]
  }, {
    path: "/customer",
    component: _3855c9d8,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _5f298e5b,
      pathToRegexpOptions: {"strict":true},
      name: "customer___pl"
    }, {
      path: "agreements",
      component: _cf77ef20,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___pl"
    }, {
      path: "beneficiaries",
      component: _0692a64c,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___pl"
    }, {
      path: "card",
      component: _086210d2,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___pl"
    }, {
      path: "edit",
      component: _5bc9dd91,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___pl"
    }, {
      path: "orders",
      component: _8d6bbaa2,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___pl"
    }, {
      path: "questionnaire/mental-health",
      component: _00f4e0fd,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___pl"
    }, {
      path: "questionnaire/participants",
      component: _2a684dd3,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___pl"
    }, {
      path: "orders/:id",
      component: _dde1f652,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___pl"
    }]
  }, {
    path: "/en",
    component: _d765e750,
    name: "index___en"
  }, {
    path: "/formularz-danych-do-faktury",
    component: _f1b46d46,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___pl"
  }, {
    path: "/mediclub",
    component: _d3a8bae0,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___pl"
  }, {
    path: "/modal-test",
    component: _234b3f2c,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___pl"
  }, {
    path: "/opinie",
    component: _00e9d2de,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___pl"
  }, {
    path: "/rate",
    component: _2634724c,
    pathToRegexpOptions: {"strict":true},
    name: "rate___pl"
  }, {
    path: "/reklamacja",
    component: _ea4910be,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___pl"
  }, {
    path: "/sitemap",
    component: _15b0233b,
    name: "sitemap___pl"
  }, {
    path: "/terms-form",
    component: _744564c4,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___pl"
  }, {
    path: "/thank-you",
    component: _399f3b7c,
    name: "thank-you___pl"
  }, {
    path: "/update-beneficiary",
    component: _7b98dfc4,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___pl"
  }, {
    path: "/zwroty",
    component: _55e5b7b9,
    pathToRegexpOptions: {"strict":true},
    name: "returns___pl"
  }, {
    path: "/auth/login",
    component: _49ac1bbc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___pl"
  }, {
    path: "/auth/logout",
    component: _38e86447,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___pl"
  }, {
    path: "/auth/password-change",
    component: _4853ed96,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___pl"
  }, {
    path: "/auth/password-reset",
    component: _41b063da,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___pl"
  }, {
    path: "/auth/register",
    component: _11c093a0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___pl"
  }, {
    path: "/c/null",
    component: _4d2063ad,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___pl"
  }, {
    path: "/checkout/EmptyCart",
    component: _1d96f4a2,
    name: "checkout-EmptyCart___pl"
  }, {
    path: "/en/ac",
    component: _4be3ea8c,
    name: "ac___en"
  }, {
    path: "/en/checkout",
    component: _318219d8,
    children: [{
      path: "",
      component: _7e744ad2,
      name: "checkout-index___en"
    }, {
      path: "auth",
      component: _2d12eb1a,
      name: "checkout-index-auth___en"
    }, {
      path: "step2",
      component: _3e8a5aab,
      name: "checkout-index-step2___en"
    }, {
      path: "step3",
      component: _3e98722c,
      name: "checkout-index-step3___en"
    }]
  }, {
    path: "/en/complaints",
    component: _ea4910be,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___en"
  }, {
    path: "/en/customer",
    component: _3855c9d8,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _5f298e5b,
      pathToRegexpOptions: {"strict":true},
      name: "customer___en"
    }, {
      path: "agreements",
      component: _cf77ef20,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___en"
    }, {
      path: "beneficiaries",
      component: _0692a64c,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___en"
    }, {
      path: "card",
      component: _086210d2,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___en"
    }, {
      path: "edit",
      component: _5bc9dd91,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___en"
    }, {
      path: "orders",
      component: _8d6bbaa2,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___en"
    }, {
      path: "questionnaire/mental-health",
      component: _00f4e0fd,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___en"
    }, {
      path: "questionnaire/participants",
      component: _2a684dd3,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___en"
    }, {
      path: "orders/:id",
      component: _dde1f652,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___en"
    }]
  }, {
    path: "/en/invoice-data-form",
    component: _f1b46d46,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___en"
  }, {
    path: "/en/mediclub",
    component: _d3a8bae0,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___en"
  }, {
    path: "/en/modal-test",
    component: _234b3f2c,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___en"
  }, {
    path: "/en/rate",
    component: _2634724c,
    pathToRegexpOptions: {"strict":true},
    name: "rate___en"
  }, {
    path: "/en/returns",
    component: _55e5b7b9,
    pathToRegexpOptions: {"strict":true},
    name: "returns___en"
  }, {
    path: "/en/reviews",
    component: _00e9d2de,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___en"
  }, {
    path: "/en/sitemap",
    component: _15b0233b,
    name: "sitemap___en"
  }, {
    path: "/en/terms-form",
    component: _744564c4,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___en"
  }, {
    path: "/en/thank-you",
    component: _399f3b7c,
    name: "thank-you___en"
  }, {
    path: "/en/update-beneficiary",
    component: _7b98dfc4,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___en"
  }, {
    path: "/p/null",
    component: _9649bb80,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___pl"
  }, {
    path: "/search/q",
    component: _aa27f298,
    name: "search-q___pl"
  }, {
    path: "/update-beneficiary/thank-you",
    component: _21f8d9f0,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___pl"
  }, {
    path: "/en/auth/login",
    component: _49ac1bbc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _38e86447,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___en"
  }, {
    path: "/en/auth/password-change",
    component: _4853ed96,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___en"
  }, {
    path: "/en/auth/password-reset",
    component: _41b063da,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___en"
  }, {
    path: "/en/auth/register",
    component: _11c093a0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___en"
  }, {
    path: "/en/c/null",
    component: _4d2063ad,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___en"
  }, {
    path: "/en/checkout/EmptyCart",
    component: _1d96f4a2,
    name: "checkout-EmptyCart___en"
  }, {
    path: "/en/p/null",
    component: _9649bb80,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___en"
  }, {
    path: "/en/search/q",
    component: _aa27f298,
    name: "search-q___en"
  }, {
    path: "/en/update-beneficiary/thank-you",
    component: _21f8d9f0,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___en"
  }, {
    path: "/en/ac/:url",
    component: _4225d103,
    name: "ac-url___en"
  }, {
    path: "/en/mediclub/:page",
    component: _022631a8,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub-page___en"
  }, {
    path: "/en/p/:slug?",
    component: _79226fa1,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en"
  }, {
    path: "/en/p/:slug/:virtual",
    component: _e6bf151e,
    name: "p-slug-virtual___en"
  }, {
    path: "/en/c/*",
    component: _fdb65c10,
    name: "category___en"
  }, {
    path: "/en/a/*",
    component: _4b219c92,
    name: "a-slug___en"
  }, {
    path: "/en/a/*",
    component: _de3e0eb2,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___en"
  }, {
    path: "/en/c/*",
    component: _0aa521ae,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___en"
  }, {
    path: "/ac/:url",
    component: _4225d103,
    name: "ac-url___pl"
  }, {
    path: "/mediclub/:page",
    component: _022631a8,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub-page___pl"
  }, {
    path: "/p/:slug?",
    component: _79226fa1,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___pl"
  }, {
    path: "/p/:slug/:virtual",
    component: _e6bf151e,
    name: "p-slug-virtual___pl"
  }, {
    path: "/en/*",
    component: _b9538156,
    name: "all1___en"
  }, {
    path: "/c/*",
    component: _fdb65c10,
    name: "category___pl"
  }, {
    path: "/a/*",
    component: _4b219c92,
    name: "a-slug___pl"
  }, {
    path: "/en/*",
    component: _b9538156,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/a/*",
    component: _de3e0eb2,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___pl"
  }, {
    path: "/c/*",
    component: _0aa521ae,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___pl"
  }, {
    path: "/",
    component: _d765e750,
    name: "index___pl"
  }, {
    path: "/*",
    component: _b9538156,
    name: "all1___pl"
  }, {
    path: "/*",
    component: _b9538156,
    pathToRegexpOptions: {"strict":true},
    name: "all___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
